import React, { useState } from 'react'
import 'twin.macro'
import { Tween, ScrollTrigger } from 'react-gsap'
import Layout from '../components/Layout'
import Header from '../components/Header'
import AnchorMenu from '../components/AnchorMenu'
import { Hero, WhatWeDo, HowItWorks, OurCommunity, HowToApply } from '../components/brands'
import Footer from '../components/Footer'

const Brands = () => {
  const sections = [
    {
      id: 'what-we-do',
      title: 'What we do',
      start: 'top 50%',
      component: (props) => <WhatWeDo {...props} />
    },
    {
      id: 'our-community',
      title: 'Our community',
      start: 'top 50%',
      component: (props) => <OurCommunity {...props} />
    },
    {
      id: 'how-it-works',
      title: 'How it works',
      start: 'top 50%',
      component: (props) => <HowItWorks {...props} />
    },
    {
      id: 'how-to-apply',
      title: 'How to apply',
      start: 'top 50%',
      component: (props) => <HowToApply {...props} />
    }
  ]

  const defaultSectionsState = sections.reduce((acc, { id }) => {
    acc[id] = false
    return acc
  }, {})

  const anchorMenuLinks = sections.map(({ id, title }) => ({ id, title }))

  const [sectionsState, setSectionsState] = useState(defaultSectionsState)

  return (
    <Layout title="Dogma Creative" description="Advertising Services">
      <Header />
      <Hero />
      <main tw="relative">
        {sections.map(({ id, start, component }) => (
          <React.Fragment key={id}>
            <ScrollTrigger
              start={start}
              end="bottom 50%"
              trigger={`#${id}`}
              onToggle={() =>
                setSectionsState((sectionsState) => ({
                  ...sectionsState,
                  [id]: !sectionsState[id]
                }))
              }
            >
              <Tween>
                <div tw="overflow-x-hidden">{component({ id })}</div>
              </Tween>
            </ScrollTrigger>
          </React.Fragment>
        ))}

        <AnchorMenu
          startSectionId="what-we-do"
          links={anchorMenuLinks}
          sectionsState={sectionsState}
        />
      </main>
      <Footer />
    </Layout>
  )
}

export default Brands
